import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../components/pageHeader";
import GroupIcon from "@mui/icons-material/Group";
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import Lottie from "lottie-web";
import animationData from "../components/animations/not-found.json";
import loadingwait from "../components/animations/loading.json";
import { ToastContainer } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import * as dayjs from "dayjs";

export default function Coupons() {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [coupons, setCoupons] = useState();
  const [feedBack, setFeedBack] = useState({ message: "", statut: "" });
  const [openSnack, setOpenSnack] = useState("");
  const loadingref = useRef();
  const containerRef = useRef();

  //libelle et pourcentage a envoyer
  const [id, setId] = useState("");
  const [libelle, setLibelle] = useState("");

  const [code, setCode] = useState("");
  const [type, seType] = useState("pourcentage");
  const [reduction, setReduction] = useState("");
  const [qte, setQte] = useState("");
  const [qtePerUser, setQtePerUser] = useState("");
  const [debut, setDebut] = useState("");
  const [fin, setFin] = useState("");

  const [percent, setPercent] = useState("");
  const token = Cookies.get("authToken");
  dayjs.locale("fr");

  const Content = () => {
    return (
      <Box className="mt-3">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={3}
            className="my-1 d-flex justify-content-center align-items-center"
          >
            <FormControl fullWidth>
              <TextField
                fullWidth
                value={code}
                label="Code"
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            className="my-1 d-flex justify-content-center align-items-center"
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Type de réduction
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Type de réduction"
                onChange={(e) => {
                  seType(e.target.value);
                }}
              >
                <MenuItem value="fixe">Fixe</MenuItem>
                <MenuItem value="pourcentage">Pourcentage</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            className="my-1 d-flex justify-content-center align-items-center"
          >
            <FormControl fullWidth>
              <NumericFormat
                fixedDecimalScale
                allowLeadingZeros
                thousandSeparator=","
                customInput={TextField}
                tabIndex={-1}
                variant="outlined"
                onValueChange={(value) =>
                  setReduction(
                    value !== "undefined" && value.floatValue !== "undefined"
                      ? value.floatValue
                      : 0.0
                  )
                }
                label="Valeur de réduction"
                allowNegative={false}
                value={reduction}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {" "}
                      {type == "pourcentage" && "%"}
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            className="my-1 d-flex justify-content-center align-items-center"
          >
            <FormControl fullWidth>
              <NumericFormat
                fixedDecimalScale
                allowLeadingZeros
                thousandSeparator=","
                customInput={TextField}
                tabIndex={-1}
                variant="outlined"
                onValueChange={(value) =>
                  setQte(
                    value !== "undefined" && value.floatValue !== "undefined"
                      ? value.floatValue
                      : 0.0
                  )
                }
                label="Quantité"
                allowNegative={false}
                value={qte}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          {/* <Grid
            item
            xs={12}
            sm={3}
            className="my-1 d-flex justify-content-center align-items-center"
          >
            <FormControl fullWidth>
              <NumericFormat
                fixedDecimalScale
                allowLeadingZeros
                thousandSeparator=","
                customInput={TextField}
                tabIndex={-1}
                variant="outlined"
                onValueChange={(value) =>
                  setQtePerUser(
                    value !== "undefined" && value.floatValue !== "undefined"
                      ? value.floatValue
                      : 0.0
                  )
                }
                label="Nombre d'utilisation par utilisateur"
                allowNegative={false}
                value={qtePerUser}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={3}
            className="my-1 d-flex justify-content-center align-items-center"
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Type d'usage
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={qtePerUser}
                label="Type d'usage"
                onChange={(e) => {
                  setQtePerUser(e.target.value);
                }}
              >
                <MenuItem value="unique">Unique</MenuItem>
                <MenuItem value="multiple">Multiple</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            className="my-1 d-flex justify-content-center align-items-center"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <FormControl fullWidth>
                <DatePicker
                  onChange={(date) => setDebut(date)}
                  label="Date de début"
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            className="my-1 d-flex justify-content-center align-items-center"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <FormControl fullWidth>
                <DatePicker
                  onChange={(date) => setFin(date)}
                  label="Date de fin"
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleCloseCreate = () => {
    setOpenDialog(false);
  };

  const handleOpenUpdate = (
    id,
    code,
    type,
    reduction,
    qte,
    qteperuser,
    debut,
    fin
  ) => {
    setId(id);
    setCode(code);
    seType(type);
    setReduction(reduction);
    setQte(qte);
    setQtePerUser(qteperuser);
    setDebut(debut);
    setFin(fin);
    setOpenDialog2(true);
  };
  const handleCloseUpdate = () => {
    setOpenDialog2(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}admin/coupons`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setCoupons(response?.data ?? []);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [reloadData]);

  const handleStore = async () => {
    if (
      code == "" ||
      reduction == "" ||
      qte == "" ||
      qtePerUser == "" ||
      debut == "" ||
      fin == ""
    ) {
      setFeedBack({
        message: "Veuillez remplir les différents champs",
        statut: false,
      });
      setOpenSnack(true);
    } else {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/coupons/store`,
          {
            code,
            type,
            reduction,
            qte,
            qtePerUser,
            debut,
            fin,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          setReloadData((prev) => !prev);
          setOpenDialog(false);
          setCode("");
          seType("pourcentage");
          setReduction("");
          setQte("");
          setQtePerUser("");
          setDebut("");
          setFin("");

          setFeedBack({ message: response.data.message, statut: true });
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setFeedBack({ message: response.data.message, statut: false });
          setOpenSnack(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlEdit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/coupons/update/${id}`,
        {
          code,
          type,
          reduction,
          qte,
          qtePerUser,
          debut,
          fin,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setReloadData((prev) => !prev);
        setFeedBack({ message: response.data.message, statut: true });
        setOpenDialog2(false);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
        setCode("");
        seType("pourcentage");
        setReduction("");
        setQte("");
        setQtePerUser("");
        setDebut("");
        setFin("");
        setId("");
      } else {
        setFeedBack({ message: response.data.message, statut: false });
        setOpenSnack(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    let deleteConfirm = window.confirm(
      "Etes vous sûr de vouloir supprimer ce coupon"
    );
    if (deleteConfirm) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}admin/coupons/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          setReloadData((prev) => !prev);
          setFeedBack({ message: response.data.message, statut: true });
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setFeedBack({ message: "Une erreur est survenue", statut: false });
          setOpenSnack(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else return;
  };

  const handleChangeEtat = async (id, statut) => {
    let changeConfirm = window.confirm(
      `Etes vous sûr de vouloir ${
        statut == 1 ? "inactiver" : "activer"
      }  ce coupon`
    );
    if (changeConfirm) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/coupons/state`,
          {
            id,
            statut,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          setReloadData((prev) => !prev);
          setFeedBack({ message: response.data.message, statut: true });
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setFeedBack({ message: "Une erreur est survenue", statut: false });
          setOpenSnack(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else return;
  };

  const searchFields = ["code"];
  const [currentPage, setCurrentPage] = useState(1);
  const [cardPerPage, setCardPerPage] = useState(10);
  const [isEmpty, setIsEmpty] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const indexOfLastItem = currentPage * cardPerPage;
  const indexOfFirstItem = indexOfLastItem - cardPerPage;
  const currentItems =
    coupons && coupons.slice(indexOfFirstItem, indexOfLastItem);
  const filteredItems =
    searchTerm !== "" && coupons
      ? coupons.filter((coupon) =>
          searchFields.some((field) =>
            coupon[field]?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      : currentItems &&
        currentItems.filter((coupon) =>
          searchFields.some((field) =>
            coupon[field]?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    handleChangePage(null, 1);
  }, [searchTerm]);

  useEffect(() => {
    filteredItems && filteredItems.length == 0
      ? setIsEmpty(true)
      : setIsEmpty(false);
  }, [filteredItems]);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: containerRef.current,
      animationData: animationData,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, [isEmpty]);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);
  const container = {
    hide: {
      opacity: 0,
      scale: 0,
    },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.3,
      },
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };
  const item = {
    hide: {
      y: -50,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenSnack(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      {feedBack.message !== "" && (
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={() => setOpenSnack(false)}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setOpenSnack(false)}
            severity={feedBack.statut ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {feedBack.message}
          </Alert>
        </Snackbar>
      )}

      <PageHeader
        title={`Coupons de réduction`}
        dialogTitle="Ajout d'un coupon de réduction"
        subtitle=""
        labelButton="Ajouter un coupon"
        content={Content()}
        loading={loading}
        icon={<GroupIcon sx={{ color: "#3887BE", fontSize: "2em" }} />}
        onClick={handleStore}
        onClose={handleCloseCreate}
        open={openDialog}
        setOpen={setOpenDialog}
      />

      {/* affichage des different type d'apprenants */}
      {filteredItems && (
        <motion.div
          style={{ marginTop: 20 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <Stack spacing={2} direction="row">
            <FormControl className="col-8 col-md-10">
              <TextField
                variant="standard"
                onChange={(e) => setSearchTerm(e.target.value)}
                label="Rechercher ici"
                value={searchTerm}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl className="col-4 col-md-2">
              <TextField
                select
                value={cardPerPage}
                label="Cadre par page"
                onChange={(e) => setCardPerPage(e.target.value)}
                sx={{ minWidth: "3em" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={coupons && coupons.length}>Tout</MenuItem>
              </TextField>
            </FormControl>
          </Stack>
        </motion.div>
      )}
      {!filteredItems ? (
        <div
          className=" d-flex justify-content-center align-items-center"
          style={{ minHeight: "70vh" }}
        >
          <div
            style={{ maxHeight: "100px", maxWidth: "100px" }}
            ref={loadingref}
          ></div>
        </div>
      ) : filteredItems.length > 0 ? (
        <Box sx={{ marginTop: 5 }}>
          <Grid container spacing={5}>
            {filteredItems.map((item) => {
              return (
                <Grid item xs={6} sm={4} md={3} xl={2}>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      pl: 2,
                    }}
                  >
                    <Typography>{item.code} </Typography>
                    <Stack direction={"row"} justifyContent={"center"}>
                      <IconButton
                        onClick={() =>
                          handleOpenUpdate(
                            item.id,
                            item.code,
                            item.type_reduction,
                            item.valeur_reduction,
                            item.quantite,
                            item.type_usage,
                            item.debut,
                            item.fin
                          )
                        }
                        aria-label="delete"
                        color="primary"
                      >
                        <ModeEditOutlineIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleChangeEtat(item.id, item.statut)}
                        aria-label="statut"
                        color={item.statut ? "secondary" : "default"}
                      >
                        <ToggleOffIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(item.id)}
                        aria-label="delete"
                        color="error"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Stack>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : filteredItems.length == 0 ? (
        <div
          className="text-center d-flex justify-content-center align-items-center"
          style={{ minHeight: "70vh" }}
        >
          <motion.div
            ref={containerRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          ></motion.div>
        </div>
      ) : (
        ""
      )}

      {/* dialog de mise a jour */}
      <Dialog
        open={openDialog2}
        onClose={handleCloseUpdate}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Modification {code}</DialogTitle>
        <DialogContent>
          <Box className="mt-3">
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={3}
                className="my-1 d-flex justify-content-center align-items-center"
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    value={code}
                    label="Code"
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className="my-1 d-flex justify-content-center align-items-center"
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Type de réduction
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Type de réduction"
                    onChange={(e) => {
                      seType(e.target.value);
                    }}
                  >
                    <MenuItem value="fixe">Fixe</MenuItem>
                    <MenuItem value="pourcentage">Pourcentage</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                className="my-1 d-flex justify-content-center align-items-center"
              >
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    variant="outlined"
                    onValueChange={(value) =>
                      setReduction(
                        value !== "undefined" &&
                          value.floatValue !== "undefined"
                          ? value.floatValue
                          : 0.0
                      )
                    }
                    label="Valeur de réduction"
                    allowNegative={false}
                    value={reduction}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          {type == "pourcentage" && "%"}
                        </InputAdornment>
                      ),
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                className="my-1 d-flex justify-content-center align-items-center"
              >
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    variant="outlined"
                    onValueChange={(value) =>
                      setQte(
                        value !== "undefined" &&
                          value.floatValue !== "undefined"
                          ? value.floatValue
                          : 0.0
                      )
                    }
                    label="Quantité"
                    allowNegative={false}
                    value={qte}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>

              {/* <Grid
                item
                xs={12}
                sm={3}
                className="my-1 d-flex justify-content-center align-items-center"
              >
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    variant="outlined"
                    onValueChange={(value) =>
                      setQtePerUser(
                        value !== "undefined" &&
                          value.floatValue !== "undefined"
                          ? value.floatValue
                          : 0.0
                      )
                    }
                    label="Nombre d'utilisation par utilisateur"
                    allowNegative={false}
                    value={qtePerUser}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid> */}

              <Grid
                item
                xs={12}
                sm={3}
                className="my-1 d-flex justify-content-center align-items-center"
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Type d'usage
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={qtePerUser}
                    label="Type d'usage"
                    onChange={(e) => {
                      setQtePerUser(e.target.value);
                    }}
                  >
                    <MenuItem value="unique">Unique</MenuItem>
                    <MenuItem value="multiple">Multiple</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                className="my-1 d-flex justify-content-center align-items-center"
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr"
                >
                  <FormControl fullWidth>
                    <DatePicker
                      onChange={(date) => setDebut(date)}
                      label="Date de début"
                      value={dayjs(debut)}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                className="my-1 d-flex justify-content-center align-items-center"
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr"
                >
                  <FormControl fullWidth>
                    <DatePicker
                      onChange={(date) => setFin(date)}
                      label="Date de fin"
                      value={dayjs(fin)}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdate}>Annuler</Button>
          <Button onClick={() => handlEdit()}>Valider</Button>
        </DialogActions>
      </Dialog>

      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        pauseOnHover={false}
      />
    </>
  );
}
