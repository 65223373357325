import React from "react";
import loadingwait from "../components/animations/loading.json";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect, useState, useRef } from "react";
import Lottie from "lottie-web";
import { motion } from "framer-motion";
import { Box, Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import * as dayjs from "dayjs";

const ShowCertification = () => {
  const loadingref = useRef();
  const { id } = useParams();
  const token = Cookies.get("authToken");
  const [certification, setCertification] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}admin/certification/show/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCertification(response.data.certification);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <>
      {certification ? (
        <motion.div className="my-4">
          <Card className="p-2">
            <Stack spacing={2}>
              <Box>
                <Box className=" d-flex justify-content-between align-items-center">
                  <span>Informations sur la certification</span>
                  <span>
                    <Link to={`/dashboard/certifications`}>
                      <IconButton size="small">
                        <ReplyOutlinedIcon />
                      </IconButton>
                    </Link>
                  </span>
                </Box>
              </Box>
              <Box>
                <Stack spacing={5} className="my-4">
                  <Card
                    className="p-2 d-flex justify-content-center text-light align-items-center"
                    sx={{
                      backgroundColor: "rgba(107, 135, 156)",
                      boxShadow: "none",
                    }}
                  >
                    Programme:&nbsp;{" "}
                    <span className="text-uppercase fw-bold">
                      {certification.programme +
                        " (" +
                        certification.code +
                        ")"}
                    </span>
                  </Card>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Card
                          className="p-2 d-flex justify-content-center  align-items-center"
                          sx={{
                            backgroundColor: "rgba(107, 135, 156,0.4)",
                            boxShadow: "none",
                          }}
                        >
                          Domaine:&nbsp;{" "}
                          <span className="text-uppercase fw-bold">
                            {certification.domaine}
                          </span>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card
                          className="p-2 d-flex justify-content-center  align-items-center"
                          sx={{
                            backgroundColor: "rgba(107, 135, 156,0.4)",
                            boxShadow: "none",
                          }}
                        >
                          Niveau:&nbsp;{" "}
                          <span className="text-uppercase fw-bold">
                            {certification.niveau}
                          </span>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card
                          className="p-2 d-flex justify-content-center  align-items-center"
                          sx={{
                            backgroundColor: "rgba(107, 135, 156,0.4)",
                            boxShadow: "none",
                          }}
                        >
                          Nombre d'heure:&nbsp;{" "}
                          <span className="text-uppercase fw-bold">
                            {certification.number_hour}
                          </span>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Grid item xs={12}>
                              <Card
                                className="p-2 d-flex justify-content-center  align-items-center"
                                sx={{
                                  backgroundColor: "rgba(107, 135, 156,0.4)",
                                  boxShadow: "none",
                                }}
                              >
                                Fonction:&nbsp;{" "}
                                <span className="text-uppercase fw-bold">
                                  {certification.role.title}{" "}
                                </span>
                              </Card>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid item xs={12}>
                              <Card
                                className="p-2 d-flex justify-content-center  align-items-center"
                                sx={{
                                  backgroundColor: "rgba(107, 135, 156,0.4)",
                                  boxShadow: "none",
                                }}
                              >
                                Cout:&nbsp;{" "}
                                <span className="text-uppercase fw-bold">
                                  {certification.cout_certif}{" "}
                                  {JSON.parse(certification.devise).currency}{" "}
                                </span>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Grid item xs={12}>
                              <Card
                                className="p-2 d-flex justify-content-center  align-items-center"
                                sx={{
                                  backgroundColor: "rgba(107, 135, 156,0.4)",
                                  boxShadow: "none",
                                }}
                              >
                                Nombre de mensualité:&nbsp;{" "}
                                <span className="text-uppercase fw-bold">
                                  {certification.nb_mensualite}{" "}
                                </span>
                              </Card>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid item xs={12}>
                              <Card
                                className="p-2 d-flex justify-content-center  align-items-center"
                                sx={{
                                  backgroundColor: "rgba(107, 135, 156,0.4)",
                                  boxShadow: "none",
                                }}
                              >
                                Coût de mensualité:&nbsp;{" "}
                                <span className="text-uppercase fw-bold">
                                  {certification.cout_mensualite.toFixed(2)}{" "}
                                  {JSON.parse(certification.devise).currency}{" "}
                                </span>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {certification.reduction && (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Grid item xs={12}>
                                <Card
                                  className="p-2 d-flex justify-content-center  align-items-center"
                                  sx={{
                                    backgroundColor: "rgba(107, 135, 156,0.4)",
                                    boxShadow: "none",
                                  }}
                                >
                                  Coût après réduction:&nbsp;{" "}
                                  <span className="text-uppercase fw-bold">
                                    {certification.reduction.type_reduction ==
                                    "pourcentage"
                                      ? certification.cout_certif -
                                        (certification.cout_certif *
                                          certification.reduction
                                            .valeur_reduction) /
                                          100
                                      : certification.cout_certif -certification.reduction.valeur_reduction}{" "}{JSON.parse(certification.devise).currency}{" "}
                                  </span>
                                </Card>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Grid item xs={12}>
                                <Card
                                  className="p-2 d-flex justify-content-center  align-items-center"
                                  sx={{
                                    backgroundColor: "rgba(107, 135, 156,0.4)",
                                    boxShadow: "none",
                                  }}
                                >
                                  Coût de mensualité:&nbsp;{" "}
                                  <span className="text-uppercase fw-bold">
                                    {certification.cout_mensualite.toFixed(2)}{" "}
                                    {JSON.parse(certification.devise).currency}{" "}
                                  </span>
                                </Card>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Box mt={5}>Offres</Box>
                    <hr />
                    {certification.mensualite_certifications.map((offer) => {
                      return (
                        <Box mt={5} fontSize={20}>
                          <Typography fontWeight={"600"}>
                            {offer.libelle}
                          </Typography>
                          <Typography>
                            Nombre de mensualité : {offer.nb_mensualite}
                          </Typography>
                          <Typography>
                            Pourcentage de réduction: {offer.percent}%
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Card>
        </motion.div>
      ) : (
        <div
          className=" d-flex justify-content-center align-items-center"
          style={{ minHeight: "65vh" }}
        >
          <div
            style={{ maxHeight: "100px", maxWidth: "100px" }}
            ref={loadingref}
          ></div>
        </div>
      )}
    </>
  );
};

export default ShowCertification;
