import React, { useState, useContext, memo, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import { useEffect } from "react";
import Cookies from "js-cookie";
import Dashboard from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BadgeIcon from "@mui/icons-material/Badge";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PersonIcon from "@mui/icons-material/Person";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import { Box } from "@mui/material";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = memo((props) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [active, setActive] = useState("");
  //location
  let location = useLocation();

  const [authInfos, setAuthInfos] = useState();

  useEffect(() => {
    try {
      setAuthInfos(JSON.parse(Cookies.get("authUser")));
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu ">
        <li className="nav-item">
          <Link
            className={`${
              location.pathname === "/dashboard" ? "active" : ""
            } nav-link `}
            to="/dashboard"
          >
            <i className="icon">
              <Dashboard className="pe-1"/>
            </i>
            <span className="item-name" style={{ textTransform: "none" }}>
              Tableau de bord
            </span>
          </Link>
        </li>
        {authInfos && authInfos.isAdmin == 1 && (
          <>
            <li className="nav-item">
              <Link
                className={`${
                  location.pathname.startsWith("/dashboard/roles")
                    ? "active"
                    : ""
                } nav-link `}
                to="/dashboard/roles"
              >
                <i className="icon">
                  <HomeRepairServiceIcon className="pe-1"/>
                </i>
                <span className="item-name" style={{ textTransform: "none" }}>
                  Roles
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${
                  (location.pathname.startsWith("/dashboard/admins")||location.pathname.startsWith("/dashboard/admin"))  ? "active" : ""
                } nav-link `}
                to="/dashboard/admins"
              >
                <i className="icon">
                  <ManageAccountsIcon className="pe-1"/>
                </i>
                <span className="item-name" style={{ textTransform: "none" }}>
                  Admins système
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${
                  (location.pathname.startsWith("/dashboard/aces")||location.pathname.startsWith("/dashboard/ace")) ? "active" : ""
                } nav-link `}
                to="/dashboard/aces"
              >
                <i className="icon">
                  <BadgeIcon className="pe-1"/>
                </i>
                <span className="item-name" style={{ textTransform: "none" }}>
                  Agents ACE
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${
                  (location.pathname .startsWith("/dashboard/agents")||location.pathname .startsWith("/dashboard/agent"))  ? "active" : ""
                } nav-link `}
                to="/dashboard/agents"
              >
                <i className="icon">
                  <AssignmentIndIcon className="pe-1"/>
                </i>
                <span className="item-name" style={{ textTransform: "none" }}>
                  Agents de notation
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${
                  (location.pathname.startsWith("/dashboard/others")||location.pathname.startsWith("/dashboard/other")) ? "active" : ""
                } nav-link `}
                to="/dashboard/others"
              >
                <i className="icon">
                  <PersonIcon className="pe-1"/>
                </i>
                <span className="item-name" style={{ textTransform: "none" }}>
                  Autres utilisateurs
                </span>
              </Link>
            </li>

            <hr />

            <li className="nav-item">
              <Link
                className={`${
                  (location.pathname.startsWith("/dashboard/certifications")||location.pathname.startsWith("/dashboard/certification"))
                    ? "active"
                    : ""
                } nav-link `}
                to="/dashboard/certifications"
              >
                <i className="icon">
                  <CardMembershipIcon className="pe-1"/>
                </i>
                <span className="item-name" style={{ textTransform: "none" }}>
                  Certifications
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${
                  (location.pathname.startsWith("/dashboard/coupons")||location.pathname.startsWith("/dashboard/coupon"))
                    ? "active"
                    : ""
                } nav-link `}
                to="/dashboard/coupons"
              >
                <i className="icon">
                  <CardMembershipIcon className="pe-1"/>
                </i>
                <span className="item-name" style={{ textTransform: "none" }}>
                  Coupons de réduction
                </span>
              </Link>
            </li>
            
            <hr />
            <li className="nav-item">
              <Link
                className={`${
                  location.pathname.startsWith("/dashboard/objectifs")
                    ? "active"
                    : ""
                } nav-link `}
                to="/dashboard/objectifs"
              >
                <i className="icon">
                  <AdsClickIcon className="pe-1"/>
                </i>
                <span className="item-name" style={{ textTransform: "none" }}>
                  Objectifs des ACE
                </span>
              </Link>
            </li>
            <hr/>

            <li className="nav-item">
              <Link
                className={`${
                  (location.pathname.startsWith("/dashboard/gestion-entreprise")||location.pathname.startsWith("/dashboard/entreprise-comptes"))
                    ? "active"
                    : ""
                } nav-link `}
                to="/dashboard/gestion-entreprise"
              >
                <i className="icon">
                  <LocationCityIcon className="pe-1"/>
                </i>
                <span className="item-name" style={{ textTransform: "none" }}>
                  Entreprises
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`${
                  (location.pathname.startsWith("/dashboard/chefs_entreprises")||location.pathname.startsWith("/dashboard/chefs_entreprise"))
                    ? "active"
                    : ""
                } nav-link `}
                to="/dashboard/chefs_entreprises"
              >
                <i className="icon">
                  <ManageAccountsIcon className="pe-1"/>
                </i>
                <span className="item-name" style={{ textTransform: "none" }}>
                  Admins d'entreprises
                </span>
              </Link>
            </li>
          </>
        )}

        {authInfos &&
          ((authInfos.isAgent == 1 || authInfos.isAce == 1 || Cookies.get("company_admin") == 1) && authInfos.isAdmin !== 1)
            && (
            <>
              <li className="nav-item">
                <Link
                  className={`${location.pathname.startsWith("/dashboard/entreprises-ace")||location.pathname.startsWith("/dashboard/gestion-entreprise")||location.pathname.startsWith("/dashboard/entreprise-comptes") ? 'active' : ''} nav-link `}
                  to={`/dashboard/entreprises-ace/${authInfos.id}`}
                >
                  <i className="icon">
                    <LocationCityIcon className="pe-1"/>
                  </i>
                  <span className="item-name" style={{ textTransform: "none" }}>
                    Mes entreprises
                  </span>
                </Link>
              </li>
            </>
          )}
      </Accordion>
    </Fragment>
  );
});

export default VerticalNav;
