import { Card, Grid, Box, Button, Stack, Tooltip, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PrintIcon from "@mui/icons-material/Print";

const PageHeader = (props) => {
  const { open, setOpen, onClose, onClick,print,handleGeneratePDF } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <Stack spacing={2} className="d-flex justify-content-center align-items-center">
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Stack direction="row" spacing={2}>
            <Card sx={{ padding: ".5em" }} className="d-flex justify-content-center align-items-center">
              {props.icon}
            </Card>
            <Stack spacing={1}>
              <span style={{ fontSize: "1em" }}> {props.title} </span>
              <span style={{ fontSize: ".8em" }}>{props.subtitle}</span>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sm={6} alignItems="center" className="text-start text-sm-end">
          <Box>
            <Button onClick={handleClickOpen} sx={{ fontSize: ".75em" }} variant="outlined" startIcon={<AddIcon sx={{ fontSize: ".75em" }} />}>
              {props.labelButton}
            </Button>
            {print && (
                <Tooltip title="Exporter l'historique des cotisations et paiements au format pdf">
                  <IconButton aria-label="print" onClick={handleGeneratePDF}>
                    <PrintIcon color="primary" />
                  </IconButton>
                </Tooltip>
            )}
            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              
            >
              <DialogTitle>{props.dialogTitle}</DialogTitle>
              <DialogContent>
                <div className="container-fluid">{props.content}</div>
              </DialogContent>
              <DialogActions>
                <Stack direction="row" spacing={2}>
                  <LoadingButton loading={props.loading} onClick={onClick} variant="outlined">
                    Enregistrer
                  </LoadingButton>
                  {!props.loading && (
                    <Button type="reset" variant="outlined" onClick={onClose}>
                      Annuler
                    </Button>
                  )}
                </Stack>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PageHeader;
