import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../components/pageHeader";
import GroupIcon from "@mui/icons-material/Group";
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import Lottie from "lottie-web";
import animationData from "../components/animations/not-found.json";
import loadingwait from "../components/animations/loading.json";
import { ToastContainer } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

export default function Apprenants() {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [apprenants, setApprenants] = useState();
  const [feedBack, setFeedBack] = useState({ message: "", statut: "" });
  const [openSnack, setOpenSnack] = useState("");
  const loadingref = useRef();
  const containerRef = useRef();

  //libelle et pourcentage a envoyer
  const [id, setId] = useState("");
  const [libelle, setLibelle] = useState("");
  const [percent, setPercent] = useState("");
  const token = Cookies.get("authToken");

  const Content = () => {
    return (
      <Box className="mt-3">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={9}
            className="my-1 d-flex justify-content-center align-items-center"
          >
            <FormControl fullWidth>
              <TextField
                fullWidth
                value={libelle}
                label="Libéllé"
                onChange={(e) => {
                  setLibelle(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <NumericFormat
                fixedDecimalScale
                allowLeadingZeros
                thousandSeparator=","
                customInput={TextField}
                tabIndex={-1}
                variant="outlined"
                onValueChange={(value) =>
                  setPercent(
                    value !== "undefined" && value.floatValue !== "undefined"
                      ? value.floatValue
                      : 0.0
                  )
                }
                label="Réduction"
                allowNegative={false}
                value={percent}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0, max: 100, maxLength: 6 },
                }}
                decimalScale={2}
                max={100}
                isAllowed={(values) => {
                  const { value } = values;
                  // Limite la valeur maximale à 100 et la limite à deux décimales
                  return value <= 100 && value >= 0;
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };

  
  const handleCloseCreate = () => {
    setOpenDialog(false);
  };

  const handleOpenUpdate = (libelle,id,percent) => {
    setLibelle(libelle)
    setId(id)
    setPercent(percent)
    setOpenDialog2(true);
  };
  const handleCloseUpdate = () => {
    setOpenDialog2(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}admin/apprenants`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setApprenants(response?.data ?? []);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [reloadData]);

  const handleStore = async () => {
    if (libelle == "" || percent == "") {
      setFeedBack({
        message: "Veuillez remplir les différents champs",
        statut: false,
      });
      setOpenSnack(true);
    } else {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/apprenants/store`,
          {
            libelle,
            percent,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          setReloadData((prev) => !prev);
          setOpenDialog(false);
          setLibelle("");
          setPercent("");
          setFeedBack({ message: response.data.message, statut: true });
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setFeedBack({ message: response.data.message, statut: false });
          setOpenSnack(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlEdit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/apprenants/update/${id}`,
        {
          libelle,
          percent,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setReloadData((prev) => !prev);
        setFeedBack({ message: response.data.message, statut: true });
        setOpenDialog2(false);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
        setLibelle('')
        setPercent('')
        setId('')
      } else {
        setFeedBack({ message: response.data.message, statut: false });
        setOpenSnack(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    let deleConfirm = window.confirm(
      "Etes vous sûr de vouloir supprimer cette catégorie d'apprenants"
    );
    if (deleConfirm) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}admin/apprenants/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          setReloadData((prev) => !prev);
          setFeedBack({ message: response.data.message, statut: true });
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setFeedBack({ message: "Une erreur est survenue", statut: false });
          setOpenSnack(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else return;
  };

  const handleChangeEtat = async (id, statut) => {
    let changeConfirm = window.confirm(
      `Etes vous sûr de vouloir ${
        statut == 1 ? "inactiver" : "activer"
      }  cette catégorie d'apprenants`
    );
    if (changeConfirm) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/apprenants/state`,
          {
            id,
            statut,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          setReloadData((prev) => !prev);
          setFeedBack({ message: response.data.message, statut: true });
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setFeedBack({ message: "Une erreur est survenue", statut: false });
          setOpenSnack(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else return;
  };

  const searchFields = ["libelle"];
  const [currentPage, setCurrentPage] = useState(1);
  const [cardPerPage, setCardPerPage] = useState(10);
  const [isEmpty, setIsEmpty] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const indexOfLastItem = currentPage * cardPerPage;
  const indexOfFirstItem = indexOfLastItem - cardPerPage;
  const currentItems =
    apprenants && apprenants.slice(indexOfFirstItem, indexOfLastItem);
  const filteredItems =
    searchTerm !== "" && apprenants
      ? apprenants.filter((apprenant) =>
          searchFields.some((field) =>
            apprenant[field]?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      : currentItems &&
        currentItems.filter((apprenant) =>
          searchFields.some((field) =>
            apprenant[field]?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    handleChangePage(null, 1);
  }, [searchTerm]);

  useEffect(() => {
    filteredItems && filteredItems.length == 0
      ? setIsEmpty(true)
      : setIsEmpty(false);
  }, [filteredItems]);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: containerRef.current,
      animationData: animationData,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, [isEmpty]);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);
  const container = {
    hide: {
      opacity: 0,
      scale: 0,
    },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.3,
      },
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };
  const item = {
    hide: {
      y: -50,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: 50,
      opacity: 0,
    },
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenSnack(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      {feedBack.message !== "" && (
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={() => setOpenSnack(false)}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setOpenSnack(false)}
            severity={feedBack.statut ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {feedBack.message}
          </Alert>
        </Snackbar>
      )}

      <PageHeader
        title={`Catégories d'apprenants`}
        dialogTitle="Ajout d'une nouvelle catégorie d'apprenants"
        subtitle="avec leurs reductions"
        labelButton="Ajouter une catégorie d'apprenants"
        content={Content()}
        loading={loading}
        icon={<GroupIcon sx={{ color: "#3887BE", fontSize: "2em" }} />}
        onClick={handleStore}
        onClose={handleCloseCreate}
        open={openDialog}
        setOpen={setOpenDialog}
      />

      {/* affichage des different type d'apprenants */}
      {filteredItems && (
        <motion.div
          style={{ marginTop: 20 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <Stack spacing={2} direction="row">
            <FormControl className="col-8 col-md-10">
              <TextField
                variant="standard"
                onChange={(e) => setSearchTerm(e.target.value)}
                label="Rechercher ici"
                value={searchTerm}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl className="col-4 col-md-2">
              <TextField
                select
                value={cardPerPage}
                label="Cadre par page"
                onChange={(e) => setCardPerPage(e.target.value)}
                sx={{ minWidth: "3em" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={apprenants && apprenants.length}>
                  Tout
                </MenuItem>
              </TextField>
            </FormControl>
          </Stack>
        </motion.div>
      )}
      {!filteredItems ? (
        <div
          className=" d-flex justify-content-center align-items-center"
          style={{ minHeight: "70vh" }}
        >
          <div
            style={{ maxHeight: "100px", maxWidth: "100px" }}
            ref={loadingref}
          ></div>
        </div>
      ) : filteredItems.length > 0 ? (
        <Box sx={{ marginTop: 5 }}>
          <Grid container spacing={5}>
            {filteredItems.map((item) => {
              return (
                <Grid item xs={6} sm={4} md={3} xl={2}>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      pl: 2,
                    }}
                  >
                    <Typography  >{item["libelle"]} ({item["percent"]}%)</Typography>
                    <Stack direction={"row"} justifyContent={"center"}>
                      <IconButton
                        onClick={() => handleOpenUpdate(item.libelle, item.id , item.percent)}
                        aria-label="delete"
                        color="primary"
                      >
                        <ModeEditOutlineIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleChangeEtat(item.id, item.statut)}
                        aria-label="statut"
                        color={item.statut ? "secondary" : "default"}
                      >
                        <ToggleOffIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(item.id)}
                        aria-label="delete"
                        color="error"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Stack>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : filteredItems.length == 0 ? (
        <div
          className="text-center d-flex justify-content-center align-items-center"
          style={{ minHeight: "70vh" }}
        >
          <motion.div
            ref={containerRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          ></motion.div>
        </div>
      ) : (
        ""
      )}

      {/* dialog de mise a jour */}
      <Dialog open={openDialog2} onClose={handleCloseUpdate}>
        <DialogTitle>Modification {libelle}</DialogTitle>
        <DialogContent>
          <Box className="mt-3">
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={9}
                className="my-1 d-flex justify-content-center align-items-center"
              >
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    value={libelle}
                    label="Libéllé"
                    onChange={(e) => {
                      setLibelle(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <NumericFormat
                    fixedDecimalScale
                    allowLeadingZeros
                    thousandSeparator=","
                    customInput={TextField}
                    tabIndex={-1}
                    variant="outlined"
                    onValueChange={(value) =>
                      setPercent(
                        value !== "undefined" &&
                          value.floatValue !== "undefined"
                          ? value.floatValue
                          : 0.0
                      )
                    }
                    label="Réduction"
                    allowNegative={false}
                    value={percent}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: { min: 0, max: 100, maxLength: 6 },
                    }}
                    decimalScale={2}
                    max={100}
                    isAllowed={(values) => {
                      const { value } = values;
                      // Limite la valeur maximale à 100 et la limite à deux décimales
                      return value <= 100 && value >= 0;
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdate}>Annuler</Button>
          <Button onClick={() => handlEdit()}>Valider</Button>
        </DialogActions>
      </Dialog>

      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        pauseOnHover={false}
      />
    </>
  );
}
